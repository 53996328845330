import React, { useState, useEffect } from "react";
// image
import calenderIcon from "../../assets/img/calender.svg";
import lozad from "lozad";
import logo from "../../assets/img/logo.svg";
const EventCardCategory = ({
  eventImg,
  title,
  time,
  desc,
  calender,
  bgcolor,
  data,
}) => {
  const { observe } = lozad("[data-use-lozad]", {
    loaded: (el) => {
      el.classList.add("fade");
    },
  });
  const [distance, setDistance] = useState(null);
  const timer = () => {
    var countDownDate = new Date(data.date_time).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDistance(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
      if (distance < 0) {
        clearInterval(x);
        setDistance(null);
        // window.location.reload();
      } else if (distance === 0) {
        window.location.reload();
      }
    }, 1000);
  };
  useEffect(() => {
    observe();
  }, [observe]);
  useEffect(() => {
    // if (distance !== null) {
    timer();
    // }
    // eslint-disable-next-line
  }, [setDistance]);
  return (
    <div className="eventscard">
      <div className="eventscard__time">
        {distance && (
          <>
            <img src={calenderIcon} alt="calender" />
            {calender} at {time}
          </>
        )}
      </div>
      <div className="eventscard__wrap">
        <div className="eventscard__img" style={{ backgroundColor: bgcolor }}>
          <img
            src={eventImg ? eventImg : logo}
            alt="logo"
            className="lozad"
            style={!eventImg ? { objectFit: "contain" } : {}}
          />
          <span className="eventscard__status">Coming Soon</span>
        </div>
        <div className="eventscard__desc">
          <h5>{title}</h5>
          <p>{desc}</p>
          {distance ? (
            <span className="eventscard__timer">{distance}</span>
          ) : (
            <span className="eventscard__timer status">Coming Soon</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCardCategory;
