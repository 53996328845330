import axios from "axios";
import io from "socket.io-client";

export const baseUrl = "https://prod1.api.degpeg.com"; // prod
var tokenview;
const contentProvider =
  window.location.href.split("?")[1] &&
  window.location.href.split("?")[1].split("=")[1]
    ? `${window.location.href.split("?")[1].split("=")[1].split("&")[0]}`
    : "";

var getUserName = (function () {
  var full_name = "";

  var getName = function () {
    if (window.localStorage !== undefined) {
      return localStorage.getItem("name");
    } else {
      return full_name;
    }
  };

  var setName = function (name) {
    if (window.localStorage !== undefined) {
      full_name = localStorage.setItem("name", name);
    } else {
      alert("Your browser is outdated!");
      full_name = name;
    }
  };

  return {
    getName: getName,
    setName: setName,
  };
})();

export default getUserName;
// axios({
//   method: "POST",
//   url: `${baseUrl}/users/auth/token`,
//   headers: { "Content-Type": "application/json" },
//   data: JSON.stringify({
//     appId: "degpegdegpeg _mediaXuUwyvni",
//     secretKey: "Nnra8P2iGqT2uJFU",
//   }),
// }).then((response) => {
//   localStorage.setItem("token", response.data.token);
//   console.log(localStorage.getItem("token"));
//   return response.data.token;
// });

export async function getpostToken() {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/users/auth/token`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      appId: "degpegdegpeg _mediaXuUwyvni",
      secretKey: "Nnra8P2iGqT2uJFU",
    }),
  }).then((response) => response);
  return await response;
}

export async function getAllSessionsForcontentProvider(token) {
  tokenview = token;
  const response = await axios
    .get(
      baseUrl + "/content-providers/" + contentProvider + "/live-sessions/",
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log();
      return res.data;
    });

  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/content-providers/${contentProvider}/live-sessions`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response.data);
  // return await response;
}

export async function getAllLiveSessionsProductCategory() {
  console.log("tokenview", tokenview);
  const response = axios({
    method: "GET",
    url: `${baseUrl}/live-session-categories/`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.data);
  return await response;
}

export async function getAllSessionData() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/session-data`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.data);
  return await response;
}

export async function getSessionById(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/live-sessions/${id}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.data);
  return await response;
}

//chat-messages
export async function getAllChatMessages() {
  const response = await fetch(baseUrl + "/chat-messages/", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function getSesseionChatMessages(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/chat-messages?filter={"where":{"liveSessionId":"${id}"}}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);

  return response;
}

export async function postSessionChatMessages(msg) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/chat-messages/`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(msg),
  }).then((response) => response);

  return response;
}

export async function getUserDetails() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users?filter={"where":{"contentProviderId":"${contentProvider}"}}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
export async function getBlockList(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/block-users?filter={"where":{"blockedby":"${id}"}}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);

  return response;
}
//Shopify-call
export async function getShopifyProductsBySessionId(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/live-sessions/${id}/ecommerce-products`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
  return await response;
}
export async function getShopifyProductsByFilter(shopName, filter) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/merchant-apps/products/${shopName}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: filter,
  }).then((response) => response);
  return await response;
}
export async function getShopDetails(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users/${id}/merchants`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return await response;
}
export async function getProductsByShopName(shopName) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/merchant-apps/products/${shopName}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return await response;
}

//Video-detail & interaction
export async function getAllProdcts(id) {
  const response = await axios
    .get(
      baseUrl +
        "/content-providers/" +
        contentProvider +
        '/live-sessions?filter={"where":{"id":"' +
        id +
        '"}}',
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/content-providers/${contentProvider}/live-sessions?filter={"where":{"id":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

//getting products
export async function getProdcts(productId) {
  const response = await axios
    .get(baseUrl + '/products?filter={"where":{"id":"' + productId + '"}}', {
      headers: {
        Authorization: `Bearer ${tokenview}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/products?filter={"where":{"id":"'${productId}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

//getting ctas
export async function getCtas(ctaId) {
  const response = await axios
    .get(baseUrl + '/user-ctas?filter={"where":{"id":"' + ctaId + '"}}', {
      headers: {
        Authorization: `Bearer ${tokenview}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/user-ctas?filter={"where":{"id":"'${ctaId}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

// theme
export async function getTheme() {
  const response = await axios
    .get(
      baseUrl +
        '/user-themes?filter={"where":{"contentProviderId":"' +
        contentProvider +
        '"}}',
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/user-ctas?filter={"where":{"id":"'${ctaId}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

// likes & shares & views

export async function getAllLikesViewsShares(id) {
  const response = await axios
    .get(
      `${baseUrl}/session-data?filter={"where":{"liveSessionId":"` + id + `"}}`,
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/session-data?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

export async function postLikes(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/likes`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getLikes_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/likes/count?filter={"where":{"liveSessionId":"` + id + `"}}`,
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/likes?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}
export async function postViews(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/views`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getViews_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/views/count?filter={"where":{"liveSessionId":"` + id + `"}}`,
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/views/count?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}
export async function postShares(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/shares`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getShares_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/shares/count?filter={"where":{"liveSessionId":"` + id + `"}}`,
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
}
export async function postPurchases(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/purchases`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getPurchases_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/purchases/count?filter={"where":{"liveSessionId":"` +
        id +
        `"}}`,
      {
        headers: {
          Authorization: `Bearer ${tokenview}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/purchases/count?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${tokenview}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}
//contentprovider Details
export async function getContentProviderDetails() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users?filter={"where":{"contentProviderId":"${contentProvider}"}}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
export async function getInfluencerDetails() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users?filter={"where":{"influencerId":"606d3f8ce30ef9d7bbaf7e2c"}}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
// lead cta
export async function getLeadById(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/cta-leads/${id}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
export async function getDownloadsById(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/cta-downloads/${id}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
export async function getPollsById(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/cta-poles/${id}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
export async function getQuizById(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/cta-quizs/${id}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}

export async function leadCapture(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/cta-client-leads`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}

export async function postDownloadsCount(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/cta-client-downloads`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return response;
}

export async function postQuizCount(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/cta-client-quizs`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return response;
}

export async function postPollCount(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/cta-client-poles`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return response;
}

export async function getPollCountById(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/cta-client-poles/${id}`,
    headers: {
      Authorization: `Bearer ${tokenview}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}

//socketConnection
export function socketConnection() {
  const response = io.connect(
    // Local Server
    // `http://localhost:9010/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    // dev Server
    // `https://dev.interactionserver.degpeg.com:9014/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    // testing
    // `https://test.interactionserver.degpeg.com:9015/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    //prod Server
    // `https://prod.interactionserver.degpeg.com:9012/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    //prod1 Server
    `https://prod1.interactionserver.degpeg.com:9015/content-provider`,
    {
      query: {
        contentProviderId: `${contentProvider}`,
      },
      transports: ["polling"],
    }
  );

  return response;
}
