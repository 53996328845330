import React from "react";
import { postDownloadsCount } from "../API";

import GoogleImg from "../../assets/img/googlestore.svg";
import AppImg from "../../assets/img/appstore.svg";

const DownloadCta = ({
  handleClose,
  show,
  contentProviderId,
  download,
  sessionData,
}) => {
  const appStorCount = () => {
    postDownloadsCount(
      JSON.stringify({
        contentProviderId: contentProviderId,
        liveSessionId: sessionData.id,
        liveSessionTitle: sessionData.name,
        liveSessionImage: sessionData.banner_url,
        storeType: "appStore",
      })
    )
      .then(function (response) {})
      .catch(function (error) {});
  };
  const playStorCount = () => {
    postDownloadsCount(
      JSON.stringify({
        contentProviderId: contentProviderId,
        liveSessionId: sessionData.id,
        liveSessionTitle: sessionData.name,
        liveSessionImage: sessionData.banner_url,
        storeType: "playStore",
      })
    )
      .then(function (response) {})
      .catch(function (error) {});
  };
  const otherStorCount = () => {
    postDownloadsCount(
      JSON.stringify({
        contentProviderId: contentProviderId,
        liveSessionId: sessionData.id,
        liveSessionTitle: sessionData.name,
        liveSessionImage: sessionData.banner_url,
        storeType: "otherStore",
      })
    )
      .then(function (response) {})
      .catch(function (error) {});
  };
  return (
    <>
      {show && (
        <div className="ctamodal__wrapper">
          <div className="ctamodal__form download">
            <h5>Downloads</h5>
            <button className="modalclosebtn" onClick={handleClose}></button>
            {download.playStoreLink && (
              <div className="ctamodal__downloads">
                <h4>Play Store</h4>
                <a
                  href={download.playStoreLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={playStorCount}
                >
                  <img src={GoogleImg} alt="play store" />
                </a>
              </div>
            )}
            {download.appStoreLink && (
              <div className="ctamodal__downloads">
                <h4>IOS Store</h4>
                <a
                  href={download.appStoreLink}
                  onClick={appStorCount}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={AppImg} alt="play store" />
                </a>
              </div>
            )}
            {download.otherLink && (
              <div className="ctamodal__downloads">
                <h4>Other Link</h4>
                <a
                  href={download.otherLink}
                  target="_blank"
                  type="submit"
                  rel="noreferrer"
                  className="btn btn-download"
                  onClick={otherStorCount}
                >
                  Download
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DownloadCta;
