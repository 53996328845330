import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorBounderies404 from "../components/ErrorBoundaries404/ErrorBoundaries404";
import Dashboard from "../container/Dashboard";

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={(props) => <Dashboard {...props} />} />
      <Route path="*">
      <ErrorBounderies404 HomeButton={true}/>
      </Route> 
    </Switch>
  </Router>
);

export default Routes;
