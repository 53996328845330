import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Error404Image from '../../assets/img/errorpage.jpg';
// import Error404Mobile from '../../assets/images/Group 51743@2x.png';

const ErrorBounderies404 = (props) => {
  const setMobileView = useMediaQuery('(min-width:750px)');

  return (
    <>
      <div className='outer-container-404-error'>
        {setMobileView ? (
          <>
            <div className='inner-container-dextop'>
              <img className='error-404-dextop-image' src={Error404Image} alt={Error404Image}/>
            </div>
          </>
        ) : (
          <>
            <div className='inner-container-mobile'>
              <img className='error-404-dextop-image-mobile' src={Error404Image} alt={Error404Image}/>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ErrorBounderies404;
