import React, { useState, useEffect } from "react";
import VideoPlayer from "./VideoPlayer";
import Moment from "moment";
import date from "date-and-time";
import thumbnailImage from "../../assets/img/default_thumbnail.png";
import { useHistory } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
import lozad from "lozad";
import { ReactComponent as PlayIcon } from "../../assets/img/play.svg";
import { ReactComponent as LoveIcon } from "../../assets/img/love.svg";
import { ReactComponent as ShareIcon } from "../../assets/img/share.svg";
// image
import {
  getAllLikesViewsShares,
  postShares,
  // postViews,
  postLikes,
} from "../API/index";
// import { Link, NavLink } from "react-router-dom";

const PlayCard = ({
  dateTime,
  status,
  titleImg,
  titleDesc,
  titleName,
  titleVideo,
  videoid,
  setData,
  setId,
  item,
  id,
}) => {
  const { observe } = lozad("[data-use-lozad]", {
    loaded: (el) => {
      el.classList.add("fade");
    },
  });
  const history = useHistory();
  const [like, setLike] = useState(false);
  const [share, setShare] = useState(false);

  const handleLiveClick = () => {
    setLike(!like);
    getAllLikesViewsShares(videoid).then((response) => {
      response.data.map((postData) => {
        const now = new Date();
        const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
        var msg = {
          liveSessionId: videoid,
          time_stamp: dt,
          userId: "",
          source: "web",
        };
        postLikes(msg).then((res) => {});
        return 0;
      });
    });
  };
  const handleShareClick = () => {
    setShare(!share);
  };

  useEffect(() => {
    observe();
  }, [observe]);

  const shareView = () => {
    getAllLikesViewsShares(videoid).then((response) => {
      response.data.map((postData) => {
        const now = new Date();
        const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
        var msg = {
          liveSessionId: videoid,
          time_stamp: dt,
          userId: "",
          source: "web",
        };
        postShares(msg).then((res) => {});
        return 0;
      });
    });
  };
  const viewUpdate = () => {
    // console.log(item);
    setData(item);
    setId(item.id);
    // setStatus(status);
    if (window.location.href.includes("&brand=")) {
      history.push(
        `/?id=${item.contentProviderId}&session=${videoid}&brand=${
          window.location.href.split("&brand=")[1]
        }`
      );
    } else {
      history.push(`/?id=${item.contentProviderId}&session=${videoid}`);
    }
    window.scrollTo(0, 0);
  };

  Moment.locale("en");

  return (
    <div className="playcard playhover" onMouseLeave={() => setShare(false)}>
      <div className="playcard__wrapper">
        <div className="playcard__titleimg">
          <img
            data-use-lozad
            data-src={titleImg === "" ? thumbnailImage : titleImg}
            alt={``}
          />
          <div className={status === "live" ? "livetag live" : "livetag"}>
            {status === "live" ? (
              <span>Live</span>
            ) : status === "scheduled" ? (
              <span>Coming Soon</span>
            ) : status === "planned" ? (
              <span>Coming Soon</span>
            ) : status === "completed" ? (
              <span>Completed</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="playcard__view">
        <div className="playcard__titleimg">
          <VideoPlayer
            source={titleVideo}
            poster={titleImg === "" ? thumbnailImage : titleImg}
            status={status}
          />
          {/* live */}
          {status === "live" ? (
            <div className="livetag">
              <span>Live</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="playcard__view-desc">
          <div className="btn-wrapper">
            {/* <NavLink
              exact
              to={{
                pathname: `/?id=${item.contentProviderId}&session=${videoid}`,
                videoUrl: titleVideo,
              }}
              className="btn btn-icon"
            >
              <PlayIcon className="stroke" onClick={viewUpdate} />
            </NavLink> */}
            {/* <Link to={"/?" + item.id}> */}
            <button
              className={`btn btn-icon ${videoid === id ? "active" : ""}`}
              disabled={videoid === id}
              onClick={viewUpdate}
            >
              <PlayIcon className="stroke" />
            </button>
            {/* </Link> */}
            <button
              className={`btn btn-icon love-icon ${like ? "active" : ""}`}
              onClick={handleLiveClick}
            >
              <LoveIcon className="stroke" />
            </button>
            <button
              className={`btn btn-icon ${share ? "active" : ""}`}
              onClick={handleShareClick}
            >
              <ShareIcon className="fill" />
            </button>
          </div>
          <div className={`btn-share-content ${share ? "active" : ""}`}>
            <FacebookShareButton
              url={
                window.location.href.indexOf("brand") > -1
                  ? window.location.href
                      .split("&brand=")[1]
                      .includes("https://")
                    ? window.location.href.split("&brand=")[1]
                    : "https://" +
                      window.location.href.split("&brand=")[1] +
                      "?&id=" +
                      item.id
                  : window.location.href.split("?")[0] +
                    "?id=" +
                    item.contentProviderId +
                    "&session=" +
                    item.id
              }
              quote={`CHECK OUT THIS - ${titleName} - ${titleDesc}`}
              hashtag="#samsung"
              onClick={shareView}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
              url={
                window.location.href.indexOf("brand") > -1
                  ? window.location.href
                      .split("&brand=")[1]
                      .includes("https://")
                    ? window.location.href.split("&brand=")[1]
                    : "https://" +
                      window.location.href.split("&brand=")[1] +
                      "?&id=" +
                      item.id
                  : window.location.href.split("?")[0] +
                    "?id=" +
                    item.contentProviderId +
                    "&session=" +
                    item.id
              }
              onClick={shareView}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <WhatsappShareButton
              title={`CHECK OUT THIS - ${titleName} - ${titleDesc}`}
              url={
                window.location.href.indexOf("brand") > -1
                  ? window.location.href
                      .split("&brand=")[1]
                      .includes("https://")
                    ? window.location.href.split("&brand=")[1]
                    : "https://" +
                      window.location.href.split("&brand=")[1] +
                      "?&id=" +
                      item.id
                  : window.location.href.split("?")[0] +
                    "?id=" +
                    item.contentProviderId +
                    "&session=" +
                    item.id
              }
              separator=" :: "
              onClick={shareView}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <LinkedinShareButton
              url={
                window.location.href.indexOf("brand") > -1
                  ? window.location.href
                      .split("&brand=")[1]
                      .includes("https://")
                    ? window.location.href.split("&brand=")[1]
                    : "https://" +
                      window.location.href.split("&brand=")[1] +
                      "?&id=" +
                      item.id
                  : window.location.href.split("?")[0] +
                    "?id=" +
                    item.contentProviderId +
                    "&session=" +
                    item.id
              }
              onClick={shareView}
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
          <h6>{titleName}</h6>
          <p>{titleDesc}</p>
          <div className="tags-wrapper">
            <span>
              {Moment.utc(new Date(dateTime), "MM/DD/YYYY h:mm A")
                .local()
                .format("DD MMMM, y hh:mm A")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayCard;
