import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes/index";
import "video.js/dist/video-js.css";
import "./index.css";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./components/shopify/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./components/shopify/style/shopify.css";
// import Client from "shopify-buy";
// import ShopifyApp from "./components/shopify/ShopifyApp";

// const client = Client.buildClient({
//   storefrontAccessToken: "165deb61992e0e9063ad908427eeb271",
//   domain: "degpeg-testing.myshopify.com",
// });
// store.dispatch({ type: "CLIENT_CREATED", payload: client });

// buildClient() is synchronous, so we can call all these after!
// client.product.fetchAll().then((res) => {
//   store.dispatch({ type: "PRODUCTS_FOUND", payload: res });
// });
// client.checkout.create().then((res) => {
//   store.dispatch({ type: "CHECKOUT_FOUND", payload: res });
// });
// client.shop.fetchInfo().then((res) => {
//   store.dispatch({ type: "SHOP_FOUND", payload: res });
// });

// ReactDOM.render(
//   <React.StrictMode>
//     <Routes />
//   </React.StrictMode>,
//   document.getElementById("root")
// );
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    {/* <ShopifyApp /> */}
  </Provider>,
  document.getElementById("root")
);
