import React, { Component } from "react";
import {
  getAllProdcts,
  getCtas,
  getProdcts,
  getPurchases_Count,
  postPurchases,
  getShopifyProductsBySessionId,
  getContentProviderDetails,
  getShopDetails,
  getShopifyProductsByFilter,
  getSessionById,
  getLeadById,
  getDownloadsById,
  getPollsById,
  getQuizById,
  socketConnection,
} from "../API";

import downArrow from "../../assets/img/down-arrow.svg";
import cartIcon from "../../assets/img/cartimg.svg";
import LeadCta from "./LeadCta";
import PollCta from "./PollCta";
import QuizCta from "./QuizCta";
import DownloadCta from "./DownloadCta";
import shopIcon from "../../assets/img/shopping-bag.gif";
import quizIcon from "../../assets/img/quiz.svg";
import pollIcon from "../../assets/img/poll.svg";
import leadIcon from "../../assets/img/lead.svg";
import downloadIcon from "../../assets/img/download.svg";

export default class Cta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      cta: [],
      minimize: false,
      shopifyProductIds: [],
      shopName: "",
      shopifyAllProducts: [],
      shopifyTest: [],
      shopifyDataList: "",
      productFilter: { limit: 250 },
      isLeadModalOpen: false,
      isDownloadModalOpen: false,
      isPollModalOpen: false,
      isQuizModalOpen: false,
      isLeadModalLive: true,
      isDownloadModalLive: true,
      isPollModalLive: true,
      isQuizModalLive: true,
      contentProviderId: "",
      leadIds: [],
      downloadIds: [],
      pollsIds: [],
      quizdIds: [],
      selectedLeadData: [],
      selectedDownload: [],
      selectedPolls: [],
      selectedQuiz: [],
      defaultLead: [],
      socketCta: null,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  componentDidMount() {
    let sampleLead = {
      firstName: true,
      lastName: true,
      mobile: true,
      email: true,
    };
    this.setState({ defaultLead: sampleLead });
    if (this.props.session_id) {
      getSessionById(this.props.session_id).then((response) => {
        // console.log("session data", response);
        // leads
        response.ctaLeadsObject &&
          response.ctaLeadsObject.length > 0 &&
          response.ctaLeadsObject.map((item) => {
            getLeadById(item).then((leads) => {
              if (leads && leads.data) {
                this.state.leadIds.push(leads.data);
              }
            });
            return 0;
          });
        // downloads
        response.ctaDownloadsObject &&
          response.ctaDownloadsObject.length > 0 &&
          response.ctaDownloadsObject.map((item) => {
            getDownloadsById(item).then((downloads) => {
              if (downloads && downloads.data) {
                this.state.downloadIds.push(downloads.data);
              }
            });
            return 0;
          });
        // polls
        response.ctaPolesObject &&
          response.ctaPolesObject.length > 0 &&
          response.ctaPolesObject.map((item) => {
            getPollsById(item).then((polls) => {
              if (polls && polls.data) {
                this.state.pollsIds.push(polls.data);
              }
            });
            return 0;
          });
        // quiz
        response.ctaQuizsObject &&
          response.ctaQuizsObject.length > 0 &&
          response.ctaQuizsObject.map((item) => {
            getQuizById(item).then((quiz) => {
              if (quiz && quiz.data) {
                this.state.quizdIds.push(quiz.data);
              }
            });
            return 0;
          });
      });
      getAllProdcts(this.props.session_id).then((response) => {
        response.data.length > 0 &&
          response.data[0].products &&
          response.data[0].products.map((item) => {
            getProdcts(item).then((data) => {
              if (data && data.data.length > 0) {
                this.setState({
                  product: [...this.state.product, data.data[0]],
                });
              }
            });
            return 0;
          });
        response.data.length > 0 &&
          response.data[0].ctaIds &&
          response.data[0].ctaIds.map((item) => {
            getCtas(item).then((data) => {
              this.setState({
                cta: [...this.state.cta, data.data[0]],
              });
            });
            return 0;
          });
      });
      getShopifyProductsBySessionId(this.props.session_id).then((response) => {
        //sessionId

        response.data.forEach((element) => {
          this.setState({
            shopifyProductIds: [
              ...this.state.shopifyProductIds,
              element.productId,
            ],
          });
          this.setState({
            shopifyDataList:
              this.state.shopifyDataList && this.state.shopifyDataList.length
                ? this.state.shopifyDataList + "," + element.productId
                : element.productId,
          });
        });
        this.state.productFilter["ids"] = [
          this.state.shopifyDataList.toString(),
        ];
        getContentProviderDetails().then((userDeatails) => {
          //contentprovider
          this.setState({
            contentProviderId: userDeatails.data[0].contentProviderId,
          });

          getShopDetails(userDeatails.data[0].id).then((shop) => {
            if (shop.data.length > 0 && this.state.shopifyDataList !== "") {
              this.setState({ shopName: shop.data[0].shopName });
              getShopifyProductsByFilter(
                shop.data[0].shopName,
                this.state.productFilter
              ).then((filterres) => {
                if (
                  filterres.data.body.products &&
                  filterres.data.body.products.length > 0
                ) {
                  filterres.data.body.products.forEach((item) => {
                    this.setState({
                      shopifyTest: [...this.state.shopifyTest, item],
                    });
                  });
                }
              });
            }
          });
        });
      });
    }
    if (this.props.status === "live") {
      const newSocketCta = socketConnection();
      this.setState({ socketCta: newSocketCta });

      var msg = {
        room: this.props.session_id,
      };
      console.log("socket connected for cta", newSocketCta);
      newSocketCta.emit("join", JSON.stringify(msg));

      console.log("before live cta", newSocketCta);
      console.log("session Id cta", this.props.session_id);
      if (this.props.status === "live") {
        console.log("live socket");
        newSocketCta.on("lead_cta_updated", this.messageListener);
        newSocketCta.on("download_cta_updated", this.downloadListener);
        newSocketCta.on("poll_cta_updated", this.pollListener);
        newSocketCta.on("quizz_cta_updated", this.quizListener);
      }
      console.log("socket connection On");
      return () => newSocketCta.close();
    }
  }
  // destroy player on unmount
  componentWillUnmount() {
    const { socketCta } = this.state;
    if (this.props.status === "live") {
      socketCta.off("lead_cta_updated", this.messageListener);
    }
  }
  messageListener = (msg) => {
    if (msg.id) {
      getLeadById(msg.id).then((leads) => {
        this.setState({ selectedLeadData: leads.data });
      });
      this.setState({
        isLeadModalOpen: msg?.status === "enabled" ? true : false,
        isLeadModalLive: msg?.status === "disabled" ? false : true,
      });
    }
  };

  downloadListener = (message) => {
    console.log("msg for cta", message);
    if (message.id) {
      getDownloadsById(message.id).then((download) => {
        this.setState({ selectedDownload: download.data });
      });
      this.setState({
        isDownloadModalOpen: message?.status === "enabled" ? true : false,
        isDownloadModalLive: message?.status === "disabled" ? false : true,
      });
    }
  };
  pollListener = (message) => {
    console.log("Message", message);
    if (message.id) {
      getPollsById(message.id).then((polls) => {
        this.setState({ selectedPolls: polls.data });
      });
      this.setState({
        isPollModalOpen: message?.status === "enabled" ? true : false,
        isPollModalLive: message?.status === "disabled" ? false : true,
      });
    }
  };
  quizListener = (message) => {
    console.log("Message", message);
    if (message.id) {
      getQuizById(message.id).then((quiz) => {
        this.setState({ selectedQuiz: quiz.data });
      });
      this.setState({
        isQuizModalOpen: message?.status === "enabled" ? true : false,
        isQuizModalLive: message?.status === "disabled" ? false : true,
      });
    }
  };
  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  hideModal = () => {
    this.setState({ isLeadModalOpen: false });
    this.setState({ isDownloadModalOpen: false });
    this.setState({ isPollModalOpen: false });
    this.setState({ isQuizModalOpen: false });
  };
  purchaseUpdateForProduct = () => {
    const now = new Date();
    const dt = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();
    var msg = {
      liveSessionId: this.props.session_id,
      time_stamp: dt,
      amount: "1",
    };
    postPurchases(msg).then((res) => {
      getPurchases_Count(this.props.session_id).then((res) => console.log(""));
    });
  };
  purchaseUpdateForCta = () => {
    const now = new Date();
    const dt = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();
    var msg = {
      liveSessionId: this.props.session_id,
      time_stamp: dt,
      amount: "0",
    };
    this.showModal();
    // this.setState({ isModalOpen: true });
    postPurchases(msg).then((res) => {
      getPurchases_Count(this.props.session_id).then((res) => console.log(""));
    });
  };
  leadCapturedata = (leads) => {
    this.setState({ isLeadModalOpen: true });
    this.setState({ selectedLeadData: leads });
  };
  downloadsData = (download) => {
    this.setState({ isDownloadModalOpen: true });
    this.setState({ selectedDownload: download });
  };
  pollsData = (poll) => {
    this.setState({ isPollModalOpen: true });
    this.setState({ selectedPolls: poll });
  };
  quizData = (quiz) => {
    this.setState({ isQuizModalOpen: true });
    this.setState({ selectedQuiz: quiz });
  };
  minimizebtn = () => {
    this.props.toggleMinimize();
  };
  render() {
    return (
      <>
        <div
          className={`cta__wrapper
        ${
          window.innerWidth > 991
            ? this.props.videoMode &&
              this.props.videoMode.toUpperCase() === "PORTRAIT"
              ? ""
              : this.state.product.length > 0 ||
                this.state.shopifyTest.length > 0 ||
                this.state.cta.length > 0 ||
                this.state.leadIds.length > 0 ||
                this.state.downloadIds.length > 0 ||
                this.state.pollsIds.length > 0 ||
                this.state.quizdIds.length > 0
              ? ""
              : "nocta"
            : ""
        }
        ${this.props.minimize ? "cta__close" : ""} ${
            this.props.videoMode &&
            this.props.videoMode.toUpperCase() === "PORTRAIT"
              ? "portrait-mode"
              : ""
          } ${
            this.props.landScape === 1 ? "portraitActive" : "portraitInactive"
          } ${
            this.props.ctaTemplateMobileView === "HORIZONTAL"
              ? "horizontal"
              : ""
          }`}
        >
          <div className="m-btn-minimize m-show" onClick={this.minimizebtn}>
            <img src={downArrow} alt="down-arrow" />
          </div>
          <div className="cta__heading">
            <h4>Products</h4>
          </div>
          {/* desktop show */}
          {this.state.shopName ||
          this.state.product.length > 0 ||
          this.state.shopifyTest.length > 0 ||
          this.state.leadIds.length > 0 ||
          this.state.downloadIds.length > 0 ||
          this.state.pollsIds.length > 0 ||
          this.state.quizdIds.length > 0 ||
          this.state.cta.length > 0 ? (
            <>
              <div
                className={`cta__detailwrap portrait-mode ${
                  this.props.landScape === 1 ? "active" : "inactive"
                }`}
              >
                {/*Shopify product fetching card */}

                <div className="cta__cardwrap">
                  {this.state.shopifyTest.map((product, index) => (
                    <div className="cta__wrap" key={index}>
                      <div className="cta__card">
                        <div className="cta__img">
                          <img
                            src={
                              product.image?.src !== null
                                ? product.image?.src
                                : ""
                            }
                            alt="img"
                          />
                        </div>
                        <div className="cta__desc">
                          <h5>{product.title}</h5>
                          <div className="cta__btngroup">
                            <h4>{product.variants[0].price}</h4>
                            <a
                              href={
                                "https://" +
                                this.state.shopName +
                                "/products/" +
                                product.handle
                              }
                              target="_blank"
                              className="btn btn__primary"
                              rel="noreferrer"
                              onClick={this.purchaseUpdateForProduct}
                            >
                              Buy Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* product fetching card */}
                  {this.state.product.map((products) => (
                    <div className="cta__wrap" key={products.id}>
                      <div className="cta__card">
                        <div className="cta__img">
                          <img src={products.image_url} alt="img" />
                        </div>
                        <div className="cta__desc">
                          <h5>{products.name}</h5>
                          <div className="cta__btngroup">
                            <h4>
                              {products.currency} {products.price}
                            </h4>
                            {products.purchase_link.includes("https://") ? (
                              <a
                                href={products.purchase_link}
                                target="_blank"
                                className="btn btn__primary"
                                rel="noreferrer"
                                onClick={this.purchaseUpdateForProduct}
                              >
                                <img src={cartIcon} alt="cart" />
                                Buy Now
                              </a>
                            ) : (
                              <a
                                href={"https://" + products.purchase_link}
                                target="_blank"
                                className="btn btn__primary"
                                rel="noreferrer"
                                onClick={this.purchaseUpdateForProduct}
                              >
                                <img src={cartIcon} alt="cart" />
                                Buy Now
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* product fetching card */}
                  {this.state.cta.map((ctas) => (
                    <div className="cta__wrap" key={ctas.id}>
                      <div className="cta__card">
                        <div className="cta__img">
                          <img src={ctas.filepath} alt="img" />
                        </div>
                        <div className="cta__desc">
                          <h4>{ctas.name}</h4>
                          {ctas.ctaLink.includes("https://") ? (
                            <button
                              // href={ctas.ctaLink}
                              target="_blank"
                              className="btn btn__primary"
                              rel="noreferrer"
                              onClick={this.purchaseUpdateForCta}
                            >
                              Click Here
                            </button>
                          ) : (
                            <button
                              href={"https://" + ctas.ctaLink}
                              target="_blank"
                              className="btn btn__primary"
                              rel="noreferrer"
                              onClick={this.purchaseUpdateForCta}
                            >
                              Click Here
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* leads */}
                  {this.state.isLeadModalLive &&
                    this.state.leadIds.map((leads) => (
                      <div className="cta__wrap" key={leads.id}>
                        <div className="cta__card">
                          <div className="cta__img">
                            <img src={leadIcon} alt="iocn" />
                          </div>
                          <div className="cta__desc">
                            <h5>{leads.title}</h5>
                            <button
                              className="btn btn__primary"
                              onClick={() => this.leadCapturedata(leads)}
                            >
                              Click Here
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* downloads */}
                  {this.state.isDownloadModalLive &&
                    this.state.downloadIds.map((downloads) => (
                      <div className="cta__wrap" key={downloads.id}>
                        <div className="cta__card">
                          <div className="cta__img">
                            <img src={downloadIcon} alt="iocn" />
                          </div>
                          <div className="cta__desc">
                            <h5>{downloads.title}</h5>
                            <button
                              className="btn btn__primary"
                              onClick={() => this.downloadsData(downloads)}
                            >
                              Click Here
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* polls */}
                  {this.state.isPollModalLive &&
                    this.state.pollsIds.map((polls) => (
                      <div className="cta__wrap" key={polls.id}>
                        <div className="cta__card">
                          <div className="cta__img">
                            <img src={pollIcon} alt="iocn" />
                          </div>
                          <div className="cta__desc">
                            <h5>{polls.title}</h5>
                            <button
                              className="btn btn__primary"
                              onClick={() => this.pollsData(polls)}
                            >
                              Click Here
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* quiz */}
                  {this.state.isQuizModalLive &&
                    this.state.quizdIds.map((quiz) => (
                      <div className="cta__wrap" key={quiz.id}>
                        <div className="cta__card">
                          <div className="cta__img">
                            <img src={quizIcon} alt="iocn" />
                          </div>
                          <div className="cta__desc">
                            <h5>{quiz.title}</h5>
                            <button
                              className="btn btn__primary"
                              onClick={() => this.quizData(quiz)}
                            >
                              Click Here
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <div className="cta__nocta">
              <p>No Product Available</p>
            </div>
          )}
        </div>
        {this.props.minimize && (
          <div
            className="cta__mobileshow"
            onClick={() => this.props.toggleCta()}
          >
            <div className="cta__cardwrap">
              {this.state.shopifyTest ||
              this.state.product ||
              this.state.cta ||
              this.state.leadIds ||
              this.state.downloadIds ||
              this.state.pollsIds ||
              this.state.quizdIds ? (
                <div className="cta__wrap">
                  <div className="cta__card">
                    <img src={shopIcon} alt="img" />
                    <span className="seemore-btn">View Products</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        <LeadCta
          show={this.state.isLeadModalOpen}
          handleClose={this.hideModal}
          contentProviderId={this.state.contentProviderId}
          leadsData={this.state.selectedLeadData}
          sessionData={this.props.session_data}
        />
        <DownloadCta
          show={this.state.isDownloadModalOpen}
          handleClose={this.hideModal}
          contentProviderId={this.state.contentProviderId}
          download={this.state.selectedDownload}
          sessionData={this.props.session_data}
        />

        <PollCta
          show={this.state.isPollModalOpen}
          handleClose={this.hideModal}
          contentProviderId={this.state.contentProviderId}
          polls={this.state.selectedPolls}
          sessionData={this.props.session_data}
          leadsData={
            this.state.selectedLeadData &&
            this.state.selectedLeadData.length > 0
              ? this.state.selectedLeadData
              : this.state.leadIds && this.state.leadIds.length > 0
              ? this.state.leadIds[0]
              : this.state.defaultLead
          }
        />
        <QuizCta
          show={this.state.isQuizModalOpen}
          handleClose={this.hideModal}
          contentProviderId={this.state.contentProviderId}
          quiz={this.state.selectedQuiz}
          sessionData={this.props.session_data}
          leadsData={
            this.state.selectedLeadData &&
            this.state.selectedLeadData.length > 0
              ? this.state.selectedLeadData
              : this.state.leadIds && this.state.leadIds.length > 0
              ? this.state.leadIds[0]
              : this.state.defaultLead
          }
        />
      </>
    );
  }
}
