import React, { useState } from "react";
import { postPollCount } from "../API";
import LeadCta from "./LeadCta";

import SuccessImg from "../../assets/img/success.gif";

const PollCta = ({
  handleClose,
  show,
  contentProviderId,
  polls,
  sessionData,
  leadsData,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);
  const [data, setData] = useState(null);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showLead, setLead] = useState(false);
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedOption) {
      postPollCount(
        JSON.stringify({
          contentProviderId: contentProviderId,
          liveSessionId: sessionData.id,
          liveSessionTitle: sessionData.name,
          liveSessionImage: sessionData.banner_url,
          question: polls.question,
          ctaPolesId: polls.id,
          selectedAnswer: selectedOption,
        })
      )
        .then((response) => {
          setData(response.data);
          setLead(true);
        })
        .catch(function (error) {});
    } else {
      alert("please select one option");
    }
    setSelectedOption("");
  };
  const handleCloseLead = () => {
    // handleClose();
    setLead(false);
    setShowPercentage(true);
    setTimeout(function () {
      setSuccessStatus(true);
    }, 3000);
  };

  return (
    <>
      {show && (
        <div className="ctamodal__wrapper">
          {!successStatus ? (
            <div className="ctamodal__form">
              <h5>{polls.question}?</h5>
              <button className="modalclosebtn" onClick={handleClose}></button>
              <form onSubmit={handleFormSubmit}>
                <label
                  id="ans1"
                  className="form-group radio-btn"
                  style={{
                    borderColor: showPercentage ? "#96b1f0" : "#414141",
                  }}
                >
                  <input
                    type="radio"
                    name="polls1"
                    id="polls1"
                    value="ans1"
                    checked={selectedOption === "ans1"}
                    onChange={handleOptionChange}
                  />
                  <span>A. {polls.ans1}</span>
                  <div
                    style={{
                      height: "100%",
                      position: "absolute",
                      zIndex: "-1",
                      width: data?.ans1Percentage + "%",
                      background: showPercentage ? "#96b1f0" : "transparent",
                    }}
                  ></div>
                  {showPercentage && (
                    <div className="poll-percentage">
                      {data?.ans1Percentage + "%"}
                    </div>
                  )}
                </label>
                <label
                  id="ans2"
                  className="form-group radio-btn"
                  style={{
                    borderColor: showPercentage ? "#96b1f0" : "#414141",
                  }}
                >
                  <input
                    type="radio"
                    name="polls1"
                    id="polls1"
                    value="ans2"
                    checked={selectedOption === "ans2"}
                    onChange={handleOptionChange}
                  />
                  <span>B. {polls.ans2}</span>
                  <div
                    style={{
                      height: "100%",
                      position: "absolute",
                      zIndex: "-1",
                      width: data?.ans2Percentage + "%",
                      background: showPercentage ? "#96b1f0" : "transparent",
                    }}
                  ></div>
                  {showPercentage && (
                    <div className="poll-percentage">
                      {data?.ans2Percentage + "%"}
                    </div>
                  )}
                </label>
                {polls.ans3 && (
                  <label id="ans3" className="form-group radio-btn ">
                    <input
                      type="radio"
                      name="polls1"
                      id="polls1"
                      value="ans3"
                      checked={selectedOption === "ans3"}
                      onChange={handleOptionChange}
                    />
                    <span>C. {polls.ans3}</span>
                    <div
                      style={{
                        height: "100%",
                        position: "absolute",
                        zIndex: "-1",
                        width: data?.ans3Percentage + "%",
                        background: showPercentage ? "#96b1f0" : "transparent",
                      }}
                    ></div>
                    {showPercentage && (
                      <div className="poll-percentage">
                        {data?.ans3Percentage + "%"}
                      </div>
                    )}
                  </label>
                )}
                {polls.ans4 && (
                  <label id="ans4" className="form-group radio-btn">
                    <input
                      type="radio"
                      name="polls1"
                      id="polls1"
                      value="ans4"
                      checked={selectedOption === "ans4"}
                      onChange={handleOptionChange}
                    />
                    <span>D. {polls.ans4}</span>
                    <div
                      style={{
                        height: "100%",
                        position: "absolute",
                        zIndex: "-1",
                        width: data?.ans4Percentage + "%",
                        background: showPercentage ? "#96b1f0" : "transparent",
                      }}
                    ></div>
                    {showPercentage && (
                      <div className="poll-percentage">
                        {data?.ans4Percentage + "%"}
                      </div>
                    )}
                  </label>
                )}
                {polls.ans5 && (
                  <label id="ans4" className="form-group radio-btn">
                    <input
                      type="radio"
                      name="polls1"
                      id="polls1"
                      value="ans5"
                      checked={selectedOption === "ans5"}
                      onChange={handleOptionChange}
                    />
                    <span>D. {polls.ans5}</span>
                    <div
                      style={{
                        height: "100%",
                        position: "absolute",
                        zIndex: "-1",
                        width: data?.ans5Percentage + "%",
                        background: showPercentage ? "#96b1f0" : "transparent",
                      }}
                    ></div>
                    {showPercentage && (
                      <div className="poll-percentage">
                        {data?.ans5Percentage + "%"}
                      </div>
                    )}
                  </label>
                )}
                {!showPercentage && (
                  <button type="submit" className="btn btn-submit">
                    Submit
                  </button>
                )}
              </form>
            </div>
          ) : (
            <div className="ctamodal__form success">
              <button
                className="modalclosebtn"
                onClick={() => {
                  handleClose();
                  setSuccessStatus(false);
                  setShowPercentage(false);
                }}
              ></button>
              <img src={SuccessImg} alt="icon" />
              <h6>Completed!</h6>
              <p>Thank you so much for participating in Polls</p>
            </div>
          )}
        </div>
      )}
      <LeadCta
        show={showLead}
        handleClose={handleCloseLead}
        contentProviderId={contentProviderId}
        leadsData={leadsData}
        sessionData={sessionData}
        leadType={"poll"}
      />
    </>
  );
};

export default PollCta;
