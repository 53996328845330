import React, { useState } from "react";
import { postQuizCount } from "../API";
import LeadCta from "./LeadCta";

import SuccessImg from "../../assets/img/success.gif";

const QuizCta = ({
  handleClose,
  show,
  contentProviderId,
  quiz,
  sessionData,
  leadsData,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showLead, setLead] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  console.log("quiz", quiz);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedOption) {
      postQuizCount(
        JSON.stringify({
          contentProviderId: contentProviderId,
          liveSessionId: sessionData.id,
          liveSessionTitle: sessionData.name,
          liveSessionImage: sessionData.banner_url,
          question: quiz.question,
          ctaQuizId: quiz.id,
          selectedAnswer: selectedOption,
        })
      )
        .then(function (response) {
          setLead(true);
          console.log("clicked", response);
        })
        .catch(function (error) {});
    } else {
      alert("please select one option");
    }
    // setSelectedOption("");
  };
  const handleCloseLead = () => {
    let allAns = ["ans1", "ans2", "ans3", "ans4"];
    var valueTrue = document.getElementById(selectedOption);
    if (quiz.trueAns === selectedOption) {
      allAns = allAns.filter((item) => {
        return item !== selectedOption;
      });
      valueTrue.classList.add("success");
      allAns?.forEach((item) => {
        document.getElementById(item)?.classList.add("partially");
      });
    } else {
      allAns = allAns.filter((item) => {
        return item !== selectedOption || item !== quiz.trueAns;
      });
      valueTrue.classList.add("false");
      var actualTrue = document.getElementById(quiz.trueAns);
      actualTrue.classList.add("success");
      allAns?.forEach((item) => {
        document.getElementById(item)?.classList.add("partially");
      });
    }
    // handleClose();
    setLead(false);
    setShowPercentage(true);
    setSelectedOption("");
    setTimeout(function () {
      setSuccessStatus(true);
    }, 3000);
  };
  return (
    <>
      {show && (
        <div className="ctamodal__wrapper">
          {!successStatus ? (
            <div className="ctamodal__form">
              <h5>{quiz.question}</h5>
              <button className="modalclosebtn" onClick={handleClose}></button>
              <form onSubmit={handleFormSubmit}>
                <label id="ans1" className={`form-group radio-btn`}>
                  <input
                    type="radio"
                    name="quiz1"
                    id="quiz1"
                    value="ans1"
                    checked={selectedOption === "ans1"}
                    onChange={handleOptionChange}
                  />
                  <span>A. {quiz.ans1}</span>
                </label>
                <label id="ans2" className="form-group radio-btn">
                  <input
                    type="radio"
                    name="quiz1"
                    id="quiz1"
                    value="ans2"
                    checked={selectedOption === "ans2"}
                    onChange={handleOptionChange}
                  />
                  <span>B. {quiz.ans2}</span>
                </label>
                {quiz.ans3 && (
                  <label id="ans3" className="form-group radio-btn ">
                    <input
                      type="radio"
                      name="quiz1"
                      id="quiz1"
                      value="ans3"
                      checked={selectedOption === "ans3"}
                      onChange={handleOptionChange}
                    />
                    <span>C. {quiz.ans3}</span>
                  </label>
                )}
                {quiz.ans4 && (
                  <label id="ans4" className="form-group radio-btn">
                    <input
                      type="radio"
                      name="quiz1"
                      id="quiz1"
                      value="ans4"
                      checked={selectedOption === "ans4"}
                      onChange={handleOptionChange}
                    />
                    <span>D. {quiz.ans4}</span>
                  </label>
                )}
                {!showPercentage && (
                  <button type="submit" className="btn btn-submit">
                    Submit
                  </button>
                )}
              </form>
            </div>
          ) : (
            <div className="ctamodal__form success">
              <button
                className="modalclosebtn"
                onClick={() => {
                  handleClose();
                  setSuccessStatus(false);
                  setShowPercentage(false);
                }}
              ></button>
              <img src={SuccessImg} alt="icon" />
              <h6>Completed!</h6>
              <p>Thank you so much for participating in Quiz</p>
            </div>
          )}
        </div>
      )}
      <LeadCta
        show={showLead}
        handleClose={handleCloseLead}
        contentProviderId={contentProviderId}
        leadsData={leadsData}
        sessionData={sessionData}
        leadType={"quiz"}
      />
    </>
  );
};

export default QuizCta;
