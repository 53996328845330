import React, { useState } from "react";
import { leadCapture } from "../API";

import SuccessImg from "../../assets/img/success.gif";

const LeadCta = ({
  handleClose,
  show,
  contentProviderId,
  leadsData,
  sessionData,
  leadType,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [attr1, setAttr1] = useState("");
  const [attr2, setAttr2] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [erroStatus, setErrorStatus] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const validateUser = (firstName) => {
    if (firstName) {
      return true;
    }
    return false;
  };

  const validateEmail = (email) => {
    if (email) {
      const reMail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reMail.test(email);
    }
  };

  const validatePhone = (mobileNumber) => {
    if (mobileNumber) {
      const rePhone = /^[6-9]\d{9}$/;
      return rePhone.test(mobileNumber);
    }
  };

  let handleSubmit = async (e) => {
    setErrorStatus(false);
    e.preventDefault();
    let firstNameVal, emailVal, phoneVal;
    leadsData.firstName
      ? (firstNameVal = validateUser(firstName))
      : (firstNameVal = true);
    leadsData.email ? (emailVal = validateEmail(email)) : (emailVal = true);
    leadsData.mobile
      ? (phoneVal = validatePhone(mobileNumber))
      : (phoneVal = true);

    if (firstNameVal && emailVal && phoneVal) {
      setErrorStatus(false);
      let newObject = {
        firstName: firstName ? firstName : "Not available",
        lastName: lastName ? lastName : "Not available",
        email: email ? email : "Not available",
        mobileNumber: mobileNumber ? mobileNumber : "Not available",
        liveSessionId: sessionData.id,
        liveSessionTitle: sessionData.name,
        liveSessionImage: sessionData.banner_url,
        contentProviderId: contentProviderId,
        attr1: leadsData.attri1,
        attr2: leadsData.attri2,
        attr1Value: attr1,
        attr2Value: attr2,
      };

      leadCapture(JSON.stringify(newObject))
        .then(function (response) {
          console.log("post", response);
          if (leadType === "poll" || leadType === "quiz") {
            handleClose();
            setSuccessStatus(false);
          } else {
            setSuccessStatus(true);
          }
          setFirstName("");
          setLastName("");
          setMobileNumber("");
          setEmail("");
        })
        .catch(function (error) {});
    } else {
      setErrorStatus(true);
      setFirstName(firstName);
      setEmail(email);
      setMobileNumber(mobileNumber);
    }
  };

  return (
    <>
      {show && (
        <div className="ctamodal__wrapper">
          {!successStatus ? (
            <div className="ctamodal__form">
              <h5>Enter details</h5>
              {leadType === "poll" || leadType === "quiz" ? (
                ""
              ) : (
                <button
                  className="modalclosebtn"
                  onClick={handleClose}
                ></button>
              )}
              <form onSubmit={handleSubmit}>
                {leadsData.firstName && (
                  <div className="form-group">
                    <input
                      type="text"
                      value={firstName}
                      name="lead-fname"
                      id="leadFname"
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      className={erroStatus && !firstName ? "error" : ""}
                    />
                    {erroStatus && !firstName && (
                      <span className="error-msg">**Name cannot be blank</span>
                    )}
                  </div>
                )}
                {leadsData.lastName && (
                  <div className="form-group">
                    <input
                      type="text"
                      value={lastName}
                      name="lead-lname"
                      id="leadLname"
                      placeholder="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                )}
                {leadsData.mobile && (
                  <div className="form-group">
                    <input
                      type="tel"
                      value={mobileNumber}
                      name="lead-mobile"
                      id="leadMobile"
                      placeholder="Mobile Number"
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className={`${erroStatus && !mobileNumber ? "error" : ""}
                  ${
                    erroStatus &&
                    mobileNumber.length > 0 &&
                    (mobileNumber.length < 10 || mobileNumber.length > 10)
                      ? "error"
                      : ""
                  }`}
                    />
                    {erroStatus && !mobileNumber && (
                      <span className="error-msg">
                        **Mobile Number Cannot be Empty
                      </span>
                    )}
                    {erroStatus &&
                      mobileNumber.length > 0 &&
                      (mobileNumber.length < 10 ||
                        mobileNumber.length > 10) && (
                        <span className="error-msg">
                          **Please Enter 10 digit mobile number
                        </span>
                      )}
                  </div>
                )}
                {leadsData.email && (
                  <div className="form-group">
                    <input
                      type="email"
                      value={email}
                      name="lead-email"
                      id="LeadEmail"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      className={erroStatus && !email ? "error" : ""}
                    />
                    {erroStatus && !email && (
                      <span className="error-msg">**Invalid Email Id</span>
                    )}
                  </div>
                )}
                {leadsData.attri1 && leadsData.attri1 !== "" ? (
                  <div className="form-group">
                    <input
                      type="text"
                      value={attr1}
                      name="lead-attr1"
                      id="LeadAttr1"
                      placeholder={leadsData.attri1}
                      onChange={(e) => setAttr1(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {leadsData.attri2 && leadsData.attri2 !== "" ? (
                  <div className="form-group">
                    <input
                      type="text"
                      value={attr2}
                      name="lead-attr2"
                      id="LeadAttr2"
                      placeholder={leadsData.attri2}
                      onChange={(e) => setAttr2(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
                <button type="submit" className="btn btn-submit">
                  Submit
                </button>
              </form>
            </div>
          ) : (
            <div className="ctamodal__form success">
              <button
                className="modalclosebtn"
                onClick={() => {
                  handleClose();
                  setSuccessStatus(false);
                  setErrorStatus(false);
                }}
              ></button>
              <img src={SuccessImg} alt="icon" />
              <h6>Congrats!</h6>
              <p>Your details are submitted successfully</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LeadCta;
