import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import UserProfile from '../API/index';

import date from "date-and-time";
import {
  getBlockList,
  getSesseionChatMessages,
  getUserDetails,
  postSessionChatMessages,
  socketConnection,
} from "../API/index";
import chatIcon from "../../assets/img/chat.svg";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      message: "",
      show_message: "",
      chat: [],
      pinChat: [],
      blockList: [],
      adminId: "",
      isChatVisible:
      window.innerWidth > 768
      ? this.props.videoMode &&
      this.props.videoMode.toUpperCase() === "PORTRAIT"
      ? false
      : true
      : false,
      userName:
      UserProfile.getName() === null
      ? ""
      : UserProfile.getName(),
      userId: UserProfile.getName(),
      socket: null,
    };
    this.publish = this.publish.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.scroll = this.scroll.bind(this);
  }
  componentDidMount() {
    ReactSession.setStoreType("sessionStorage");
    getSesseionChatMessages(this.props.session_id).then((result) => {
      // console.log("chat", result);
      let filterChat = result.data.filter((element) => {
        if (!element.isPinned || element.isPinned === false) {
          return element;
        }
      });
      this.setState({ chat: filterChat });
      result.data.map((ele) => {
        if (ele.isPinned) {
          if (ele.isPinned === true) {
            this.setState({ pinChat: [...this.state.pinChat, ele.message] });
          }
        }
      });
    });
    // console.log(this.props.session_id);
    getUserDetails().then((res) => {
      this.setState({ adminId: res.data[0].id });
      getBlockList(res.data[0].id).then((list) => {
        list.data.forEach((element) => {
          this.setState({
            blockList: [...this.state.blockList, element.blockedId],
          });
        });
      });
    });
    if (this.props.status === "live") {
      const newSocket = socketConnection();
      this.setState({ socket: newSocket });

      var msg = {
        room: this.props.session_id,
      };
      // console.log("socket connected", newSocket);
      newSocket.emit("join", JSON.stringify(msg));

      // console.log("before live", newSocket);
      // console.log("session Id", this.props.session_id);
      if (this.props.status === "live" || this.props.session_id) {
        newSocket.on("chat_message", this.messageListener);
      }
      if (this.state.chat) {
        // console.log(this.state.chat);
      }
      // console.log("socket connection On");
      return () => newSocket.close();
    }
  }
  // destroy player on unmount
  componentWillUnmount() {
    const { socket } = this.state;
    if (this.player) {
      this.player.dispose();
    }
    if (this.props.status === "live") {
      socket.off("chat_message", this.messageListener);
    }
  }
  messageListener = (message) => {
    console.log("Message from chat", message);
    const newMessages = {};
    newMessages["userName"] = message.userId;
    newMessages["message"] = message.message;
    // console.log(newMessages);
    if (!message.isPinned || message.isPinned === false) {
      this.setState({ chat: [...this.state.chat, newMessages] });
    }
    this.scroll();
  };

  shareListener = (share) => {
    // console.log("shares", share);
  };
  handleChange({ target }) {
    this.setState(() => ({
      message: target.value,
    }));
  }
  handleCloseModal() {
    this.setState({ showModal: false });
    if (UserProfile.getName()) {
      this.setState({ userName: UserProfile.getName() });
    }
  }
  scroll() {
    setTimeout(() => {
      var len = document.querySelector(".live__chat__body").lastElementChild;

      if (len !== null) {
        len.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 400);
  }
  togglechat = () => {
    this.setState((prevState) => ({ isChatVisible: !prevState.isChatVisible }));
    this.props.toggleMinimize();
  };
  publish(e) {
    if (this.state.userName) {
      e.preventDefault();
      const now = new Date();
      const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
      const { socket, message } = this.state;
      var msg = {
        time_stamp: dt,
        userName: this.state.userName,
        userId: this.state.userId,
        message: message,
        session_id: this.props.session_id,
        isPinned: false,
        // device_id: "web",
        // channelId: "channel",
      };
      var postMsg = {
        time_stamp: dt,
        userName: this.state.userName,
        userId: this.state.userName,
        message: message,
        liveSessionId: this.props.session_id,
        isPinned: false,
      };
      msg = JSON.stringify(msg);

      postSessionChatMessages(postMsg).then((res) => {
        // console.log(res);
        getSesseionChatMessages(this.props.session_id).then((result) => {
          // console.log(result);
          let filterChat = result.data.filter((element) => {
            if (!element.isPinned || element.isPinned === false) {
              return element;
            }
          });
          this.setState({ chat: filterChat });
          result.data.map((ele) => {
            if (ele.isPinned) {
              if (ele.isPinned === true) {
                this.setState({
                  pinChat: [...this.state.pinChat, ele.message],
                });
              }
            }
          });
          // console.log(result);
          if (this.props.status === "live") {
            // console.log(socket);
            socket.emit("chat_message", msg);
          }
          this.scroll();
        });
      });
      // console.log("message", msg);
      this.setState({ message: "" });
    } else {
      e.preventDefault();
      this.setState({ showModal: true });
      const s = window.prompt("Please enter your name");
      console.log(s);
      this.setState({ userName: s });
      this.setState({ userId: s });
      UserProfile.setName(s);
    }
  }
  render() {
    const { isChatVisible } = this.state;
    return (
      <div
        id="liveChat"
        className={`live__chat ${
          isChatVisible ? (window.innerWidth > 768 ? "isclose" : "") : ""
        } ${this.props.landScape === 2 ? "active" : "inactive"} ${
          this.props.minimize ? "is_minimized" : ""
        }
        ${
          this.props.videoMode &&
          this.props.videoMode.toUpperCase() === "PORTRAIT"
            ? "portrait-mode"
            : ""
        }`}
      >
        <div
          className={
            this.state.pinChat
              ? "live__chat__header pin-chat"
              : "live__chat__header"
          }
          onClick={this.togglechat}
        >
          <h5>
            {this.state.pinChat.length > 0 && !isChatVisible ? (
              <div className="pinchat-live">
                {this.state.pinChat[this.state.pinChat.length - 1]}
              </div>
            ) : (
              "Chat"
            )}
            <span className="m-hide btn-chat">
              <img src={chatIcon} alt="chat" />
              <span className="live_count"></span>
            </span>
          </h5>
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
            <path
              d="M16 1.55392C16.0009 1.70497 15.9719 1.85471 15.9148 1.99454C15.8577 2.13438 15.7735 2.26157 15.6671 2.36881L8.78071 9.25524C8.56567 9.469 8.27477 9.58899 7.97156 9.58899C7.66834 9.58899 7.37745 9.469 7.1624 9.25524L0.275982 2.36881C0.0879519 2.14925 -0.0103014 1.86682 0.000855961 1.57796C0.0120133 1.2891 0.131759 1.01509 0.336165 0.810688C0.54057 0.606282 0.81458 0.486535 1.10344 0.475378C1.3923 0.46422 1.67473 0.562474 1.89429 0.750504L7.96582 6.82203L14.0374 0.750504C14.1972 0.589337 14.4012 0.479187 14.6237 0.433983C14.8461 0.38878 15.077 0.410552 15.2871 0.496547C15.4971 0.582542 15.677 0.728897 15.8039 0.917107C15.9308 1.10532 15.999 1.32693 16 1.55392V1.55392Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="live__chat__body">
          {/* msg */}
          {[...Object.values(this.state.chat)]
            .sort((a, b) => a.time - b.time)
            .map((payload, index) =>
              this.state.blockList.includes(payload.userId) ? (
                ""
              ) : payload.userName !== UserProfile.getName() ? (
                <div
                  className={
                    payload.userId && payload.userId === this.state.adminId
                      ? "chat-message receive admin-msg"
                      : "chat-message receive"
                  }
                  key={index}
                  id={payload.id}
                >
                  <div className="chat-message-msg">
                    <div className="chat-avatar">
                      <span>
                        {payload.userName ? payload.userName.split("@")[0] : ""}
                      </span>
                    </div>
                    <h4>{payload.message}</h4>
                  </div>
                </div>
              ) : (
                <div className="chat-message" key={index} id={payload.id}>
                  <div className="chat-message-msg">
                    <div className="chat-avatar">
                      <span>
                        {payload.userName ? payload.userName : ""}
                      </span>
                    </div>
                    <h4>{payload.message}</h4>
                  </div>
                </div>
              )
            )}
        </div>
        <div className="live__chat__footer">
          {this.state.blockList.includes(this.state.userId) ? (
            <form>
              <input placeholder="You are blocked by host" disabled />
            </form>
          ) : (
            <form>
              <input
                placeholder="Send Message"
                value={this.state.message}
                onChange={this.handleChange}
                required
              />
              <button onClick={this.publish}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M22.9823 10.3552L2.66351 0.195732C2.3417 0.03396 1.97944 -0.0296496 1.62178 0.0128131C1.26411 0.0552759 0.926813 0.201938 0.651831 0.434561C0.376157 0.66736 0.175139 0.976183 0.0738589 1.3225C-0.0274217 1.66881 -0.0244804 2.03728 0.0823156 2.38194L3.08605 11.9994L0.0823156 21.6169C-0.00251505 21.8916 -0.0216614 22.1825 0.0264172 22.466C0.0744957 22.7495 0.188456 23.0178 0.359129 23.2492C0.529801 23.4806 0.752419 23.6688 1.00907 23.7985C1.26572 23.9282 1.54923 23.9958 1.83679 23.996C2.12364 23.9971 2.40677 23.931 2.66351 23.8031L22.9823 13.6436C23.2881 13.4913 23.5452 13.2567 23.725 12.9662C23.9048 12.6758 24 12.341 24 11.9994C24 11.6578 23.9048 11.323 23.725 11.0325C23.5452 10.7421 23.2881 10.5075 22.9823 10.3552ZM1.85516 22.168L4.68437 12.918H12.2718C12.5154 12.918 12.749 12.8212 12.9213 12.6489C13.0936 12.4767 13.1904 12.243 13.1904 11.9994C13.1904 11.7558 13.0936 11.5221 12.9213 11.3499C12.749 11.1776 12.5154 11.0808 12.2718 11.0808H4.68437L1.83679 1.83998L22.1556 11.9994L1.85516 22.168Z"
                    fill="white"
                  />
                </svg>
              </button>
            </form>
          )}
        </div>
        {this.props.ischatenabled === false && (
          <div className="chat-disabled">
            <p>The Administrator has disabled the chat.</p>
          </div>
        )}
      </div>
    );
  }
}
